
import * as ipxRuntime$Y0326V2HVd from 'C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/@nuxt+image-edge@1.1.0-28416198.1e7d37b/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$Y0326V2HVd, defaults: {} }
}
        